import { Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import Link from 'next/link';
import Image from 'next/image';
export default function HeroSlider({ settings, className }) {
    const slideshowSettings = {
        autoplay: true,
        showThumbs: false,
        indicators: true,
        infinite: true,
        cssClass: 'absolute',
        duration: 2000,
        prevArrow: (
            <button className="text-maturity w-9 h-9 hover:text-trust-500 ">
                <i className="flaticon-left-arrow pointer-events-none text-4xl"></i>
            </button>
        ),
        nextArrow: (
            <button className="text-maturity w-9 h-9 z-20 hover:text-trust-500">
                <i className=" flaticon-right-arrow pointer-events-none text-4xl"></i>
            </button>
        )
    };

    return (
        settings.slides.length > 0 && (
            <div className={`hero ${className}`}>
                <div className="dark-side"></div>
                <Fade {...slideshowSettings}>
                    {settings.slides.map((slide, index) => (
                        <div key={slide.id.toString()} className="hero-slide relative">
                           <div className="slide-content relative z-10">
                                {slide.label && (
                                    <span
                                        className={`label label-sm ${
                                            slide.labelStyle ? slide.labelStyle : ''
                                        }`}
                                    >
                                        {slide.label}
                                    </span>
                                )}
                                <span dangerouslySetInnerHTML={{ __html: slide.content }}></span>
                                {slide.buttons.length > 0 && (
                                    <div>
                                        {slide.buttons.map((btn) => (
                                            <Link key={btn.id.toString()} href={btn.link}>
                                                <a target={btn.target} className={`btn ${btn.style}`}>
                                                    {btn.label}
                                                </a>
                                            </Link>
                                        ))}
                                    </div>
                                )}
                            </div>
             
                            <Image 
                                className="object-cover w-full z-0"
                                src={slide.image.data.attributes.url} 
                                layout="fill" 
                                quality={100}
                                priority={index < 2 ? true : false}
                                alt={slide.image.data.attributes.alternativeText} />
                      
                        </div>
                    
                    ))}
                </Fade>
            </div>
        )
    );
}
