import Link from 'next/link';
export default function Hero({ settings, style }) {
    return (
        <div className={`hero  ${style}`}>
            <div className="dark-side"></div>

            {settings.map((slide) => (
                <div key={slide.id.toString()} className="hero-slide">
                    <div style={{ backgroundImage: `url(${bg})` }}>
                        <div className="skew-bottom"></div>
                        <span dangerouslySetInnerHTML={{ __html: slide.content }}></span>
                        {slide.buttons.length > 0 && (
                            <div>
                                {slide.buttons.map((btn) => (
                                    <Link key={btn.id.toString()} href={btn.link}>
                                        <a target={btn.target} className={`btn mr-2 ` + btn.style}>
                                            {btn.label}
                                        </a>
                                    </Link>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            ))}
        </div>
    );
}
